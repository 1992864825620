export default {
  methods: {
    selectPromptText(name) {
      const firstLetter = name[0].toLowerCase();
      const article = ["a", "e", "i", "o", "u"].includes(firstLetter)
        ? "an"
        : "a";

      return `Select ${article} ${name}`;
    },
  },
};
