<template>
  <div>
    <input
      type="text"
      class="is-search form-control rounded-lg"
      style="border-width: 1px"
      placeholder="Search"
      v-model="query"
      v-if="optionsLimitExceeded"
      ref="searchBox"
      @click.stop
      @input="search"
    />
    <ul class="m-0" ref="list">
      <div v-show="showResults">
        <slot></slot>
      </div>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import StylesheetMixin from "./mixins/StylesheetMixin";

export default defineComponent({
  name: "SearchableList",
  mixins: [StylesheetMixin],

  props: {
    searchableThreshold: { type: Number, default: 5 },
    hideOnEmptyQuery: { type: String, default: "false" },
  },

  data: function () {
    return {
      query: "",
      listLength: null,
      slotElement: null,
      slotChangedListener: null,
    };
  },

  computed: {
    optionsLimitExceeded() {
      return this.listLength >= this.searchableThreshold;
    },
    showResults() {
      if (this.hideOnEmptyQuery === "true") {
        return this.query.length > 0;
      } else {
        return true;
      }
    },
  },

  mounted() {
    this.listItems = this.$el.querySelector("slot").assignedElements();
    this.listLength = this.listItems.length;

    // Listen for any further slot changes to keep listItems and listLength updated.
    this.slotElement = this.$el.querySelector("slot");
    this.slotChangedListener = function (event) {
      this.listItems = event.target.assignedElements();
      this.listLength = this.listItems.length;
      this.search();
    }.bind(this);

    this.slotElement.addEventListener("slotchange", this.slotChangedListener);
  },

  beforeUnmount() {
    this.slotElement.removeEventListener(
      "slotchange",
      this.slotChangedListener
    );
  },

  methods: {
    search() {
      this.listItems.forEach((item) => {
        const itemText = item.textContent.toLowerCase();
        if (itemText.includes(this.query.toLowerCase())) {
          item.style.display = "";
        } else {
          item.style.display = "none";
        }
      });
    },
  },
});
</script>

<style lang="scss">
input[type="text"] {
  border-width: 0 0 1px 0;
  border-color: #e1e1e6;
  padding: 8px 20px 8px 10px;
  border-radius: 6px 6px 0 0;
  width: 100%;
  margin-top: -4px;
}

ul {
  overflow-y: auto;
  max-height: 15.5rem;
  margin: 0;
}

.is-search {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" height="18" width="18"><path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="rgb(138, 138, 143)" /></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center;
}
</style>
