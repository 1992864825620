<template>
  <div class="filter-rule--row align-items-start">
    <span class="rule-row-label mb-2 mt-1">{{
      index == 0 ? "where" : "and"
    }}</span>
    <div class="btn btn-tertiary btn-sm mb-2">
      <span class="material-icons-outlined md-small mr-1">{{ icon }}</span>
      {{ name }}
    </div>
    <details class="filter-dropdown ml-2 mb-2" ref="keySelector">
      <summary
        v-if="selectedKey === null"
        class="btn btn-outline btn-sm btn-icon"
      >
        <span class="material-icons-outlined icon" aria-hidden="true">
          ads_click
        </span>
        Select a Key
      </summary>
      <summary v-else class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedKey }}
        </span>
      </summary>
      <div class="filter-dropdown--list drop-right">
        <vntg-searchable-list>
          <li
            v-for="key in availableKeys"
            class="dropdown-list--item"
            @click="selectKey(key)"
          >
            {{ key }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <details class="filter-dropdown ml-2 mb-2" ref="operatorSelector">
      <summary class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedOperator.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list drop-right">
        <vntg-searchable-list>
          <li
            v-for="operator in operators"
            class="dropdown-list--item"
            @click="selectOperator(operator)"
          >
            {{ operator.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <details class="filter-dropdown ml-2 mb-2" ref="valueSelector">
      <summary
        v-if="loading === true"
        class="btn btn-outline btn-sm btn-icon disabled"
      >
        <span class="material-icons-outlined icon" aria-hidden="true">
          timer
        </span>
        Loading…
      </summary>
      <summary
        v-if="selectedValue === null"
        :class="`btn btn-outline btn-sm btn-icon ${
          selectedKey === null ? 'disabled' : ''
        }`"
      >
        Any Value
      </summary>
      <summary v-else class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedValue }}
        </span>
      </summary>
      <div class="filter-dropdown--list">
        <vntg-searchable-list>
          <li
            v-for="value in availableValues"
            class="dropdown-list--item"
            @click="selectValue(value)"
          >
            {{ value }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <button
      type="button"
      class="btn btn-sm btn-invisible btn-icon mb-2"
      aria-expanded="true"
      @click="removeRule(this)"
    >
      <span class="material-icons-outlined md-small">delete</span>
    </button>
  </div>
</template>

<script>
const operators = [
  { name: "is", value: "=" },
  { name: "is not", value: "!=" },
];

export default {
  name: "ResourceInventoryFilterKeyEnumRule",
  props: [
    "field",
    "icon",
    "index",
    "name",
    "keyField",
    "options",
    "removeRule",
    "serializedRule",
    "uuid",
    "valueField",
    "fetchValues",
  ],
  data: (_component) => ({
    operators: operators,
    selectedOperator: operators[0],
    selectedKey: null,
    selectedValue: null,
    loading: false,
    values: {},
  }),
  watch: {
    selectedOperator() {
      this.$emit("ruleChanged");
    },
    selectedKey() {
      this.$emit("ruleChanged");
    },
    selectedValue() {
      this.$emit("ruleChanged");
    },
  },
  computed: {
    availableKeys() {
      const keys = this.options.map((option) => option.name);
      return keys.sort((a, b) => a.localeCompare(b));
    },
    availableValues() {
      if (this.selectedKey === null) {
        return [];
      }

      const values = this.values[this.selectedKey] || [];
      return values.sort();
    },
  },
  mounted: async function () {
    if (this.serializedRule) {
      // Serialized rule is an and-ed (^) query.
      const queries = this.serializedRule["^"];
      const keyQuery = queries[0];
      const valueQuery = queries[1];
      let operator;

      if (valueQuery) {
        const operatorValue = Object.keys(valueQuery)[0];

        operator = this.operators.find(
          (operator) => operator.value === operatorValue
        );

        this.selectOperator(operator);
      }

      const option = this.options.find(
        (option) => option.name === keyQuery["="][1]
      );

      if (option != null) {
        await this.selectKey(option.name);
        if (operator) {
          this.selectValue(valueQuery[operator.value][1]);
        }
      }
    }
  },
  methods: {
    selectOperator(operator, closeDropdown = true) {
      this.selectedOperator = operator;

      if (closeDropdown) {
        this.$refs.operatorSelector.open = false;
      }
    },
    async selectKey(key, closeDropdown = true) {
      if (this.selectedKey !== key) {
        this.selectedKey = key;
        this.selectedValue = null;
      }

      if (!this.values.hasOwnProperty(key)) {
        this.loading = true;
        const response = this.fetchValues(key);
        const data = await (await response).json();

        this.values[key] = data;
        this.loading = false;
      }

      if (closeDropdown) {
        this.$refs.keySelector.open = false;
      }
    },
    selectValue(value, closeDropdown = true) {
      this.selectedValue = value;

      if (closeDropdown) {
        this.$refs.valueSelector.open = false;
      }
    },
    serialize() {
      if (this.selectedKey === null) {
        return null;
      } else {
        let queries = [];
        const keyQuery = {
          "=": [this.field + "." + this.keyField, this.selectedKey],
        };

        queries.push(keyQuery);

        if (this.selectedValue) {
          const valueQuery = {};
          valueQuery[this.selectedOperator.value] = [
            this.field + "." + this.valueField,
            this.selectedValue,
          ];

          queries.push(valueQuery);
        }

        return {
          "^": queries,
        };
      }
    },
  },
};
</script>

<style scoped></style>
