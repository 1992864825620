<template>
  <div class="drop-menu" v-if="active" v-click-away="onClickAway">
    <slot />
  </div>
</template>

<script>
import { mixin as VueClickAway } from "vue3-click-away";

export default {
  props: {},
  mixins: [VueClickAway],
  name: "AppDropdownContent",
  inject: ["sharedState"],
  computed: {
    active() {
      return this.sharedState.active;
    },
  },
  methods: {
    onClickAway() {
      this.sharedState.active = false;
    },
  },
};
</script>
