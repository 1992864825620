import { defineCustomElement } from "vue";
import kebabCase from "lodash/kebabCase";

// You can read more about the pros/cons of using Vue Web Components vs Vue Components here:
// https://vuejs.org/guide/extras/web-components.html#web-components-vs-vue-components

// Instantiate Vue Web Components as custom elements
// Note: This is the recommended implementation of vue components when possible.
const modules = import.meta.glob("../vue/**/*.ce.vue", { eager: true });
for (const path in modules) {
  const module = modules[path];
  const componentName = path
    .match(/[\w-]+\.ce\.vue$/)[0]
    .replace(".ce.vue", "");
  const customElementName = kebabCase(componentName);

  window.customElements.define(
    `vntg-${customElementName}`,
    defineCustomElement(module.default)
  );
}
