<template>
  <div class="filter-rule--row align-items-start">
    <span class="rule-row-label mb-2 mt-1">{{
      index == 0 ? "where" : "and"
    }}</span>
    <div class="btn btn-tertiary btn-sm mb-2">
      <span class="material-icons-outlined md-small mr-1">{{ icon }}</span>
      {{ name }}
    </div>
    <details class="filter-dropdown ml-2 mb-2" ref="operatorSelector">
      <summary class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedOperator.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list drop-right">
        <vntg-searchable-list>
          <div v-for="operator in operators">
            <div
              class="dropdown-list--item text-wrap"
              @click="selectOperator(operator)"
            >
              {{ operator.name }}
            </div>
          </div>
        </vntg-searchable-list>
      </div>
    </details>
    <input
      type="text"
      name="ruleValue"
      class="form-control mx-2 mb-2 w-auto"
      v-model="value"
      @change="serialize"
    />
    <button
      type="button"
      class="btn btn-sm btn-invisible btn-icon mb-2"
      aria-expanded="true"
      @click="removeRule(this)"
    >
      <span class="material-icons-outlined md-small">delete</span>
    </button>
  </div>
</template>

<script>
const operators = [
  { name: "is", value: "=" },
  { name: "is not", value: "!=" },
  { name: "contains", value: "=~" },
  { name: "does not contain", value: "!=~" },
];
export default {
  name: "ResourceInventoryFilterTextRule",
  props: [
    "field",
    "icon",
    "index",
    "name",
    "removeRule",
    "serializedRule",
    "uuid",
  ],
  data: (component) => ({
    operators: operators,
    selectedOperator: operators[0],
    value: null,
  }),
  watch: {
    selectedOperator() {
      this.$emit("ruleChanged");
    },
    value() {
      this.$emit("ruleChanged");
    },
  },
  mounted: function () {
    if (this.serializedRule) {
      const operatorValue = Object.keys(this.serializedRule)[0];
      const operator = this.operators.find(
        (operator) => operator.value === operatorValue
      );

      this.selectedOperator = operator;
      this.value = this.serializedRule[operator.value][1];
    }
  },
  methods: {
    selectOperator(operator, closeDropdown = true) {
      this.selectedOperator = operator;

      if (closeDropdown) {
        this.$refs.operatorSelector.open = false;
      }
    },
    serialize() {
      if (this.selectedValue === null || this.selectedValue === "") {
        return null;
      } else {
        const query = {};
        query[this.selectedOperator.value] = [this.field, this.value];

        return query;
      }
    },
  },
};
</script>

<style scoped></style>
