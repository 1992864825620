<template>
  <div>
    <VueMultiselect
      label="name"
      track-by="value"
      v-model="selected"
      :class="{ 'full-width-multiselect': fullWidth }"
      :options="options"
      :select-label="selectLabelToShow"
      :placeholder="hideSelections ? '' : placeholder"
      :tag-placeholder="tagPlaceholder"
      :internal-search="!searchUrl"
      :close-on-select="!multiple"
      :loading="isLoading"
      :allow-empty="allowEmpty"
      :disabled="disabled"
      :multiple="multiple"
      :taggable="taggable"
      @search-change="debouncedSearch"
      @select="onSelect"
      @tag="onTag"
      @open="onOpen"
      @close="onClose"
      hide-selected
    >
      <template v-if="noResultText" #noResult>{{ noResultText }}</template>
      <template v-if="noOptionsText" #noOptions>{{ noOptionsText }}</template>

      <!-- Slots used to replace selected input values -->
      <template v-if="hideSelections && !this.opened" #selection>
        <div>{{ placeholder }}</div>
      </template>
      <template v-else-if="hideSelections && this.opened" #selection>
        <div></div>
      </template>

      <template v-if="verboseOptions" #option="props">
        <div v-if="props.option.title" class="option__title">
          {{ props.option.title }}
        </div>
        <div class="option__body">{{ props.option.name }}</div>
        <div v-if="props.option.desc" class="option__desc">
          {{ props.option.desc }}
        </div>
      </template>

      <template #afterList>
        <li @click="loadMore" v-if="hasMore">
          <div class="multiselect__option">
            <div class="load-more">Load More</div>
          </div>
        </li>
      </template>
    </VueMultiselect>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import debounce from "lodash/debounce";
import VueMultiselect from "vue-multiselect";

export default defineComponent({
  name: "MultiSelectInput",

  components: { VueMultiselect },

  props: {
    idProp: {
      type: String,
      required: true,
    },
    optionsProp: {
      type: String,
      default: "[]",
    },
    selectedProp: {
      type: String,
      default: "[]",
    },
    placeholder: {
      type: String,
      default: "Pick a value",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      required: true,
    },
    searchUrl: {
      type: String,
      default: "",
    },
    commaSeparatedInput: {
      type: Boolean,
      default: false,
    },
    hideSelections: {
      type: Boolean,
      default: false,
    },
    verboseOptions: {
      type: Boolean,
      default: false,
    },
    allowEmptyProp: {
      type: String,
      default: "true",
    },
    disableSelectLabel: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: [Boolean, "true" | "false"],
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    linkTargetId: {
      type: String,
      default: "",
    },
    linkTargetClasses: {
      type: String,
      default: "",
    },
    noOptionsText: {
      type: String,
      default: "",
    },
    noResultText: {
      type: String,
      default: "",
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    tagPlaceholder: {
      type: String,
      default: "Press enter to create a tag",
    },
  },
  data: () => ({
    options: [],
    selected: [],
    isLoading: false,
    page: 1,
    perPage: 25,
    searchQuery: null,
    hasMore: false,
    opened: false,
  }),

  computed: {
    allowEmpty() {
      return JSON.parse(this.allowEmptyProp);
    },
    selectLabelToShow() {
      return this.disableSelectLabel ? "" : "Press enter to select";
    },
  },

  mounted() {
    this.options = JSON.parse(this.optionsProp);
    this.selected = JSON.parse(this.selectedProp);
    this.getOptions();
  },
  watch: {
    selected(newSelected) {
      if (this.commaSeparatedInput) {
        this.updateCommaSeparatedInput(newSelected);
      } else {
        this.updateHiddenInput(newSelected);
      }

      this.updateLinkTarget(newSelected);

      const e = {
        event: new Event("change"),
        selected: newSelected,
      };
      this.$emit("vntg:multi-select-on-change", e);
    },
    selectedProp(newValue) {
      this.selected = JSON.parse(newValue);
    },
  },

  methods: {
    debouncedSearch: debounce(function (q) {
      this.getOptions(q);
    }, 500),

    async getOptions(q = null, persistPage = false) {
      if (!this.searchUrl) return;

      let url = "";
      this.searchQuery = q;
      this.isLoading = true;
      if (this.searchQuery !== null) {
        if (!persistPage) {
          this.page = 1;
          this.options = [];
        }
        url = `${this.searchUrl}?q=${this.searchQuery}&page=${this.page}&per_page=${this.perPage}`;
      } else {
        url = `${this.searchUrl}?&page=${this.page}&per_page=${this.perPage}`;
      }

      try {
        const response = await fetch(url);
        const { data, has_more } = await response.json();
        this.options = [...this.options, ...data];
        this.hasMore = has_more;
      } catch (e) {
        console.error(e);
        this.options = [];
      } finally {
        this.isLoading = false;
      }
    },

    loadMore() {
      this.page += 1;
      this.getOptions(this.searchQuery, true);
    },

    updateHiddenInput(value) {
      let container = document.getElementById(this.idProp);
      container.innerHTML = "";
      value = Array.isArray(value) ? value : [value];
      value.forEach((selected) => {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = this.inputName;
        input.value = selected ? selected.value : "";
        container.appendChild(input);
      });
    },

    updateCommaSeparatedInput(value) {
      let inputElement = document.querySelector(`input[name="${this.inputName}"`);
      inputElement.value = value.map((v) => v.value).join(",");
    },

    updateLinkTarget(value) {
      const item = Array.isArray(value) ? value[0] : value;
      if (!this.linkTargetId || this.multiple || !item) return;

      const target = document.getElementById(this.linkTargetId);
      target.innerHTML = `<a href="${item.url}" target="_blank" class="${this.linkTargetClasses}">Go to ${item.name}</a>`;
    },

    onSelect(event) {
      const e = {
        event: event,
        selected: this.selected,
      };
      this.$emit("vntg:multi-select-on-select", e);
    },

    onTag(tag) {
      const option = { name: tag, value: tag };
      this.options = [...this.options, option];
      this.selected = [...this.selected, option];

      const e = {
        event: new Event("tag"),
        selected: this.selected,
      };
      this.$emit("vntg:multi-select-on-select", e);
    },
    onOpen(event) {
      this.opened = true;
    },
    onClose(event) {
      this.opened = false;
    },
  },
});
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style lang="scss">
.full-width-multiselect {
  .multiselect__content-wrapper {
    min-width: 100%;
    width: auto;
  }
}

.multiselect__tag {
  background: #9c25c7;
  padding: 6px 26px 6px 8px;
}

.multiselect__placeholder {
  display: none;
}

.multiselect__option--highlight {
  background: #9c25c7;

  .option__title {
    color: #fff;
  }
}

.multiselect__option--highlight:after {
  background: #9c25c7;
}

.multiselect__option--selected {
  background: #f4f4f7;
  font-weight: normal;
  color: #8a8a8f;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #f4f4f7;
  font-weight: normal;
  color: #1a1a1c;
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #f4f4f7;
  color: #8a8a8f;
}

.multiselect__tag-icon::after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background: #9c25c7;
}

.multiselect__input,
.multiselect__single {
  padding: 0 0 0 0;
  font-size: 14px;
}

.multiselect__placeholder {
  margin-left: 4px;
  color: #999999;
}

.load-more {
  padding: 12px 6px;
  border-radius: 6px;
  background-color: #f7f7f9;
  border: 1px solid #e3e3e9;
  text-align: center;
  width: 100%;
  color: #303037;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.load-more:hover {
  color: #1a1a1c;
  border-color: #cfcfd6;
  background-color: #fff;
}

.option__title {
  font-size: 12px;
  line-height: 1;
  margin-bottom: 4px;
  color: #8a8a8f;
}
</style>
