<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center p-3 border-bottom sticky">
      <h6 class="m-0">Filters</h6>
      <div class="tw-flex tw-gap-x-2">
        <button
          type="button"
          class="tw-shadow-sm tw-border tw-border-solid tw-border-b-gray-300 tw-bg-white tw-rounded-md tw-border-gray-200 hover:tw-border-gray-300 tw-px-2 tw-h-7 tw-text-xs tw-font-medium tw-flex tw-items-center tw-gap-x-1 tw-transition-colors"
          @click="addFilter"
        >
          <span class="material-icons tw-text-lg icon tw-text-gray-400 tw-leading-none">add</span>
          New Filter
        </button>
        <button
          type="button"
          class="tw-h-7 tw-px-2 tw-flex tw-items-center tw-rounded-md tw-text-xs tw-text-white tw-from-purple-600 tw-to-purple-700 tw-bg-gradient-to-b tw-font-medium tw-border tw-border-purple-700 tw-gap-x-1 hover:tw-border-purple-950 hover:tw-text-white tw-transition-colors tw-border-b-purple-950 tw-shadow-sm"
          @click="runFilter"
        >
          <span class="material-icons md-small mr-0">play_arrow</span>
          Apply
        </button>
      </div>
    </div>
    <ResourceInventoryFilter
      v-for="(filter, index) in filters"
      :key="filter.uuid"
      :index="index"
      :filter-count="filters.length"
      :uuid="filter.uuid"
      :providers="providers"
      :accounts="accounts"
      :providerAccounts="providerAccounts"
      :resourceTypes="resourceTypes"
      :regions="regions"
      :metadataKeys="metadataKeys"
      :tagNames="tagNames"
      :aliases="aliases"
      :serializedFilter="filter.serializedFilter"
      :removeFilter="removeFilter"
      :fetchMetadataValues="fetchMetadataValues"
      :fetchTagValues="fetchTagValues"
      :loading="loading"
      @filter-changed="onFilterChanged"
      ref="filters"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ResourceInventoryFilter from "./components/ResourceInventoryFilter.vue";
import StylesheetMixin from "./mixins/StylesheetMixin";
import UUIDMixin from "./mixins/UUIDMixin";
import Rails from "@rails/ujs";

export default defineComponent({
  name: "ResourceInventoryFilterSet",
  props: [
    "accountsPath",
    "providerAccountsPath",
    "providersPath",
    "regionsPath",
    "resourceTypesPath",
    "metadataKeysPath",
    "metadataValuesPath",
    "tagNamesPath",
    "tagValuesPath",
    "filterBlob",
    "aliasesBlob",
  ],

  components: {
    ResourceInventoryFilter,
  },

  mixins: [StylesheetMixin, UUIDMixin],

  data: function () {
    return {
      aliases: [],
      accounts: [],
      providerAccounts: [],
      providers: [],
      resourceTypes: [],
      regions: [],
      metadataKeys: [],
      tagNames: [],
      filters: [],
      outsideClickHandler: null,
      loading: true,
    };
  },

  watch: {
    filters: {
      deep: true,
      handler() {
        this.onFilterChanged();
      },
    },
  },

  mounted: async function () {
    var providersResponse = await this.fetchAttributeValues(this.providersPath);
    var regionsResponse = await this.fetchAttributeValues(this.regionsPath);
    var accountsResponse = await this.fetchAttributeValues(this.accountsPath);
    var providerAccountsResponse = await this.fetchAttributeValues(this.providerAccountsPath);
    var resourceTypesResponse = await this.fetchAttributeValues(this.resourceTypesPath);
    var metadataKeysResponse = await this.fetchAttributeValues(this.metadataKeysPath);
    var tagNamesResponse = await this.fetchAttributeValues(this.tagNamesPath);

    this.providers = await providersResponse.json();
    this.regions = await regionsResponse.json();
    this.accounts = await accountsResponse.json();
    this.providerAccounts = await providerAccountsResponse.json();
    this.resourceTypes = await resourceTypesResponse.json();
    this.metadataKeys = await metadataKeysResponse.json();
    this.tagNames = await tagNamesResponse.json();
    this.aliases = JSON.parse(this.aliasesBlob);

    // We're done fetching data, so we can stop showing the loading indicator.
    this.loading = false;

    const urlParams = new URLSearchParams(window.location.search);
    const filterBlob = urlParams.get("filter") || this.filterBlob;

    /*
      Sample filterBlob:
      {"||":[{"^":[{"=":["provider_id",1]},{"||":[{"=":["access_credentials.account_id","153271892032"]}]},{"||":[{"=":["type","ProviderResource::Aws::CloudfrontDistribution"]}]}]}]}
    */
    if (filterBlob !== null && filterBlob !== "") {
      try {
        const serializedFilterSet = JSON.parse(filterBlob);
        this.deserialize(serializedFilterSet);
      } catch (exception) {
        if (window.location.hostname === "localhost") {
          console.error(exception);
        } else {
          Sentry.captureException(exception);
        }
      }
    }

    // Default to an empty filter if there are none.
    if (this.filters.length === 0) {
      this.addFilter();
    }

    // Close dropdowns when the user clicks outside of them.
    const self = this;
    this.outsideClickHandler = function (clickEvent) {
      const dropdowns = self.$el.querySelectorAll("details");
      dropdowns.forEach((dropdown) => {
        if (!dropdown.contains(clickEvent.target)) {
          dropdown.open = false;
        }
      });
    };
    document.addEventListener("click", this.outsideClickHandler);
  },
  unmounted: function () {
    document.removeEventListener("click", this.outsideClickHandler);
  },
  methods: {
    fetchAttributeValues: function (path) {
      return fetch(path, {
        method: "GET",
        credentials: "same-origin",
        headers: { "X-CSRF-Token": Rails.csrfToken() },
      });
    },

    addFilter(serializedFilter) {
      this.filters.push({
        index: this.filters.length,
        serializedFilter: serializedFilter,
        uuid: crypto.randomUUID(),
      });
    },

    removeFilter(filter) {
      const index = this.filters.findIndex((f) => f.uuid === filter.uuid);
      this.filters.splice(index, 1);

      // Always leave at least one empty filter. Also allows "delete filter" to work as a reset button if there's
      // only one filter.
      if (this.filters.length === 0) {
        this.addFilter();
      }
    },

    fetchTagValues(tagName) {
      return fetch(`${this.tagValuesPath}&tag_name=${tagName}`, {
        method: "GET",
        credentials: "same-origin",
        headers: { "X-CSRF-Token": Rails.csrfToken() },
      });
    },

    fetchMetadataValues(provider, group, key) {
      return fetch(
        `${this.metadataValuesPath}&provider_id=${provider.id}&resource_type=${group.value}&metadata_key=${key.field}`,
        {
          method: "GET",
          credentials: "same-origin",
          headers: { "X-CSRF-Token": Rails.csrfToken() },
        }
      );
    },

    onFilterChanged() {
      const event = new CustomEvent("vntg:filter-set-changed", {
        bubbles: true,
        composed: true,
        detail: {
          filterSet: JSON.stringify(this.serialize()),
        },
      });

      // To be captured by an input element on the page that updates the
      // serialized filter value.
      this.$el.dispatchEvent(event);
    },

    deserialize(serializedFilterSet) {
      if (serializedFilterSet["||"] != null) {
        serializedFilterSet["||"].forEach((serializedFilter) => {
          this.addFilter(serializedFilter);
        });
      }
    },

    serialize() {
      const filters = this.$refs.filters || [];
      const serializedFilters = filters
        .map((filter) => filter.serialize())
        .filter((serializedFilter) => serializedFilter !== null);

      if (serializedFilters.length === 0) {
        return {};
      } else {
        // We OR all rules together since each rule only applies to a single provider.
        return {
          "||": serializedFilters,
        };
      }
    },

    runFilter() {
      const currentUrl = new URL(window.location.href);
      const currentSearchParams = currentUrl.searchParams;
      const serializedFilterSet = this.serialize();

      if (Object.keys(serializedFilterSet).length > 0) {
        currentSearchParams.set("filter", JSON.stringify(serializedFilterSet));
      } else {
        currentSearchParams.delete("filter");
      }

      currentUrl.search = currentSearchParams.toString();
      window.location.assign(currentUrl);
    },
  },
});
</script>

<style lang="scss">
.filter-block {
  header {
    padding: 16px 16px 0;
    font-weight: 600;
  }
  .filter-block--row {
    padding: 8px 16px 8px;
    display: flex;
    align-items: center;
  }
  .filter-rule--row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px 0 28px;

    .rule-row-label {
      margin-right: 8px;
      flex-shrink: 0;
      width: 43px;
      text-align: right;
    }

    .custom-select,
    .form-control {
      height: 32px;
      line-height: 1;
    }

    .btn {
      display: flex;
      align-items: center;
      min-height: 32px;
      line-height: 18px;
    }
  }
  .filter-block--footer {
    border-bottom: 1px solid #e3e3e9;
    padding: 0 16px 16px;
  }
  details summary::-webkit-details-marker {
    display: none;
  }
  details summary.disabled {
    pointer-events: none;
  }
  .btn.provider-icon {
    padding-left: 34px;
    background-position: 10px center;
  }
  &:last-child {
    margin-bottom: 300px;
  }
}
.filter-dropdown {
  position: relative;

  .filter-dropdown--list {
    padding: 4px 0;
    border: 1px solid #e3e3e9;
    border-radius: 6px;
    position: absolute;
    right: 0;
    transform: translate(0px, 0px);
    min-width: 200px;
    max-width: 280px;
    background: #fff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    z-index: 999;

    &.drop-right {
      right: unset;
      left: 0;
    }

    .dropdown-list--item {
      padding: 6px 10px;
      display: flex;
      white-space: normal;
      color: #111;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: #f7f7f9;
      }

      &--disabled {
        color: #6c757d;
        pointer-events: none;
      }
    }
  }
}
</style>
