<template>
  <div class="filter-rule--row align-items-start">
    <span class="rule-row-label mb-2 mt-1">{{ index == 0 ? "where" : "and" }}</span>
    <div class="btn btn-tertiary btn-sm mb-2">
      <span class="material-icons-outlined md-small mr-1">{{ icon }}</span>
      {{ name }}
    </div>
    <details class="filter-dropdown ml-2 mb-2" ref="groupSelector">
      <summary v-if="selectedGroup === null" class="btn btn-outline btn-sm btn-icon">
        <span class="material-icons-outlined icon" aria-hidden="true"> ads_click </span>
        Select a {{ groupLabel || "Group" }}
      </summary>
      <summary v-else class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedGroup.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list">
        <vntg-searchable-list>
          <li
            v-for="group in availableGroups"
            class="dropdown-list--item text-wrap"
            @click="selectGroup(group)"
          >
            {{ group.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <details class="filter-dropdown ml-2 mb-2" ref="keySelector">
      <summary
        v-if="selectedKey === null"
        :class="`btn btn-outline btn-sm btn-icon ${selectedGroup === null ? 'disabled' : ''}`"
      >
        <span class="material-icons-outlined icon" aria-hidden="true"> ads_click </span>
        Select a Key
      </summary>
      <summary v-else class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedKey.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list">
        <vntg-searchable-list>
          <li v-for="key in availableKeys" class="dropdown-list--item" @click="selectKey(key)">
            {{ key.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <details class="filter-dropdown ml-2 mb-2" ref="operatorSelector">
      <summary class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedOperator.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list drop-right">
        <vntg-searchable-list>
          <li
            v-for="operator in operators"
            class="dropdown-list--item"
            @click="selectOperator(operator)"
          >
            {{ operator.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <input
      v-if="usingFreeformOperator"
      class="form-control mx-2 mb-2 w-auto"
      type="text"
      name="optionValue"
      @change="serialize"
      v-model="selectedValue"
    />
    <details
      v-else-if="selectedOperator.hasOperand"
      class="filter-dropdown ml-2 mb-2"
      ref="valueSelector"
    >
      <summary v-if="loading === true" class="btn btn-outline btn-sm btn-icon disabled">
        <span class="material-icons-outlined icon" aria-hidden="true"> timer </span>
        Loading…
      </summary>
      <summary
        v-if="selectedValue === null"
        :class="`btn btn-outline btn-sm btn-icon ${selectedKey === null ? 'disabled' : ''}`"
      >
        <span class="material-icons-outlined icon" aria-hidden="true"> ads_click </span>
        Select a Value
      </summary>
      <summary v-else class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedValue }}
        </span>
      </summary>
      <div class="filter-dropdown--list">
        <vntg-searchable-list>
          <li
            v-for="value in availableValues"
            class="dropdown-list--item"
            @click="selectValue(value)"
          >
            {{ value }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <button
      type="button"
      class="btn btn-sm btn-invisible btn-icon mb-2"
      aria-expanded="true"
      @click="removeRule(this)"
    >
      <span class="material-icons-outlined md-small">delete</span>
    </button>
  </div>
</template>

<script>
const operators = [
  { name: "is", value: "=", hasOperand: true, hasEnum: true },
  { name: "is not", value: "!=", hasOperand: true, hasEnum: true },
  { name: "contains", value: "=~", hasOperand: true, hasEnum: false },
  { name: "does not contain", value: "!=~", hasOperand: true, hasEnum: false },
  { name: "is greater than", value: ">", hasOperand: true, hasEnum: false },
  { name: "is less than", value: "<", hasOperand: true, hasEnum: false },
  { name: "is empty", value: "!&", hasOperand: false, hasEnum: false },
  { name: "is not empty", value: "&", hasOperand: false, hasEnum: false },
];

export default {
  name: "ResourceReportFilterGroupedKeyEnumRule",
  props: [
    "field",
    "icon",
    "index",
    "name",
    "groupLabel",
    "keyField",
    "options",
    "removeRule",
    "serializedRule",
    "uuid",
    "valueField",
    "fetchValues",
  ],
  data: (_component) => ({
    operators: operators,
    selectedOperator: operators[0],
    selectedGroup: null,
    selectedKey: null,
    selectedValue: null,
    values: {},
    loading: false,
  }),
  watch: {
    selectedOperator() {
      this.$emit("ruleChanged");
    },
    selectedGroup() {
      this.$emit("ruleChanged");
    },
    selectedKey() {
      this.$emit("ruleChanged");
    },
    selectedValue() {
      this.$emit("ruleChanged");
    },
  },
  computed: {
    availableGroups() {
      const groups = this.options.filter((option) => option.group).map((option) => option.group);

      return [...new Map(groups.map((group) => [group.value, group])).values()].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },

    availableKeys() {
      let filteredKeys;

      if (this.selectedGroup === null) {
        return [];
      } else {
        filteredKeys = this.options.filter(
          (option) => option.group.value === this.selectedGroup.value
        );
      }

      return filteredKeys
        .filter(
          (option, index, self) => self.findIndex((el) => el.field === option.field) === index
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    },

    availableValues() {
      const cacheKey = this.cacheKeyFor(this.selectedKey);
      if (this.selectedKey === null || this.values[cacheKey] == null) {
        return [];
      }

      return this.values[cacheKey].sort();
    },

    // Operators that allow free text input.
    usingFreeformOperator() {
      return (
        this.selectedOperator !== null &&
        this.selectedOperator.hasOperand &&
        !this.selectedOperator.hasEnum
      );
    },
  },
  mounted: async function () {
    if (this.serializedRule) {
      // Serialized rule is an and-ed (^) query.
      const queries = this.serializedRule["^"];

      // Select the group that determines which keys we show.
      const groupQuery = queries.shift();
      const groupField = groupQuery["="][0];
      const groupValue = groupQuery["="][1];

      this.selectGroup(
        this.availableGroups.find(
          (group) => group.field === groupField && group.value === groupValue
        )
      );

      // Now we select key, operator and enum value.
      const keyValueQuery = queries.shift();
      const operatorValue = Object.keys(keyValueQuery)[0];

      const operator = this.operators.find((operator) => operator.value === operatorValue);

      this.selectOperator(operator);

      const option = this.options.find(
        (option) => this.field + "." + option.field === keyValueQuery[operator.value][0]
      );

      await this.selectKey(option);
      this.selectValue(keyValueQuery[operator.value][1]);
    }
  },
  methods: {
    selectOperator(operator, closeDropdown = true) {
      // If we're switching from contains/'does not contain' to is/'is not' operator, clear out selectedValue.
      if (this.usingFreeformOperator) {
        if (operator.value.hasEnum) {
          this.selectedValue = null;
        }
      }

      this.selectedOperator = operator;

      if (closeDropdown) {
        this.$refs.operatorSelector.open = false;
      }
    },
    selectGroup(group, closeDropdown = true) {
      if (group?.value !== this.selectedGroup?.value) {
        this.selectedGroup = group;
        this.selectedKey = null;
        this.selectedValue = null;
      }

      if (closeDropdown) {
        this.$refs.groupSelector.open = false;
      }
    },
    async selectKey(key, closeDropdown = true) {
      if (this.selectedKey !== key) {
        this.selectedKey = key;
        this.selectedValue = null;
      }

      const cacheKey = this.cacheKeyFor(key);
      if (!this.values.hasOwnProperty(cacheKey)) {
        this.loading = true;
        const response = this.fetchValues(this.selectedGroup, key);
        const data = await (await response).json();

        this.values[cacheKey] = data;
        this.loading = false;
      }

      if (closeDropdown) {
        this.$refs.keySelector.open = false;
      }
    },
    selectValue(value, closeDropdown = true) {
      this.selectedValue = value;

      if (closeDropdown) {
        this.$refs.valueSelector.open = false;
      }
    },
    cacheKeyFor(key) {
      if (key == null) {
        return null;
      } else {
        return `${key.group.value}:${key.field}`;
      }
    },
    serialize() {
      if (
        this.selectedGroup === null ||
        this.selectedKey === null ||
        (this.selectedOperator.hasOperand && this.selectedValue === null)
      ) {
        return null;
      } else {
        const queries = [];
        const groupQuery = {};

        groupQuery["="] = [this.selectedGroup.field, this.selectedGroup.value];

        queries.push(groupQuery);

        const valueQuery = {};

        if (this.selectedOperator.hasOperand) {
          valueQuery[this.selectedOperator.value] = [
            this.field + "." + this.selectedKey.field,
            this.selectedValue,
          ];
        } else {
          valueQuery[this.selectedOperator.value] = [this.field + "." + this.selectedKey.field];
        }

        queries.push(valueQuery);

        return {
          "^": queries,
        };
      }
    },
  },
};
</script>

<style scoped></style>
