<template>
  <div class="filter-block">
    <header>
      {{ header }}
    </header>
    <div class="filter-block--row">
      from
      <details class="filter-dropdown ml-2" ref="providerSelector">
        <summary v-if="loading" class="btn btn-outline btn-sm btn-icon mr-2 disabled">
          <span class="material-icons-outlined icon" aria-hidden="true"> timer </span>
          Loading…
        </summary>
        <summary
          v-else-if="selectedProvider === null"
          :class="`btn btn-outline btn-sm btn-icon mr-2 ${providers.length == 0 ? 'disabled' : ''}`"
        >
          <span class="material-icons-outlined icon" aria-hidden="true"> ads_click </span>
          Select a Provider
        </summary>
        <summary
          v-else
          :class="`btn btn-outline btn-sm btn-icon mr-2 provider-icon ${selectedProvider.key}`"
        >
          {{ selectedProvider.name }}
        </summary>
        <div class="filter-dropdown--list drop-right">
          <vntg-searchable-list>
            <div v-for="provider in sortedProviders">
              <div class="dropdown-list--item" @click="selectProvider(provider)">
                <span :class="`provider-icon dropdown-provider-icon ${provider.key}`">{{
                  provider.name
                }}</span>
              </div>
            </div>
          </vntg-searchable-list>
        </div>
      </details>
    </div>
    <component
      v-for="(rule, index) in rules"
      v-bind:is="rule.type"
      :key="rule.uuid"
      :index="index"
      :uuid="rule.uuid"
      :icon="rule.icon"
      :name="rule.name"
      :field="rule.field"
      :groupLabel="rule.groupLabel"
      :keyField="rule.keyField"
      :valueField="rule.valueField"
      :options="rule.options"
      :serializedRule="rule.serializedRule"
      :removeRule="removeRule"
      :fetchValues="rule.fetchValues"
      @rule-changed="onRuleChanged"
      ref="rules"
    ></component>
    <div class="filter-block--footer">
      <div class="d-flex">
        <details v-if="selectedProvider === null" class="filter-dropdown mr-2">
          <summary class="btn btn-tertiary btn-xs btn-disabled" aria-expanded="true">
            New Rule
          </summary>
        </details>
        <details v-else class="filter-dropdown mr-2" ref="ruleSelector">
          <summary class="btn btn-tertiary btn-xs" aria-expanded="true">New Rule</summary>
          <div class="filter-dropdown--list drop-right">
            <vntg-searchable-list v-show="selectedProvider">
              <!-- TODO(nel): add a rulesForProvider() method that determines the available rules for each provider. -->
              <div>
                <div
                  v-if="availableRegions.length > 0"
                  :class="`dropdown-list--item ${
                    regionRulePresent ? 'dropdown-list--item--disabled' : ''
                  }`"
                  @click="addRule('region')"
                >
                  <span class="material-icons-outlined md-small mr-1">outlined_flag</span>
                  Region
                </div>
                <div
                  v-if="availableAccounts.length > 0"
                  :class="`dropdown-list--item ${
                    accountRulePresent ? 'dropdown-list--item--disabled' : ''
                  }`"
                  @click="addRule('account')"
                >
                  <span class="material-icons-outlined md-small mr-1">account_circle</span>
                  {{ accountLabel }}
                </div>
                <div
                  v-if="availableProviderAccounts.length > 0"
                  :class="`dropdown-list--item ${
                    providerAccountRulePresent ? 'dropdown-list--item--disabled' : ''
                  }`"
                  @click="addRule('provider_account')"
                >
                  <span class="material-icons-outlined md-small mr-1">supervised_user_circle</span>
                  {{ providerAccountLabel }}
                </div>
                <div
                  v-if="availableResourceTypes.length > 0"
                  :class="`dropdown-list--item ${
                    resourceTypeRulePresent ? 'dropdown-list--item--disabled' : ''
                  }`"
                  @click="addRule('type')"
                >
                  <span class="material-icons-outlined md-small mr-1">category</span>
                  Resource Type
                </div>
                <div class="dropdown-list--item" @click="addRule('label')">
                  <span class="material-icons-outlined md-small mr-1">label</span>
                  Label
                </div>
                <div class="dropdown-list--item" @click="addRule('uuid')">
                  <span class="material-icons-outlined md-small mr-1">fingerprint</span>
                  {{ uuidLabel }}
                </div>
                <div
                  v-if="availableMetadataKeys.length > 0"
                  class="dropdown-list--item"
                  @click="addRule('metadata')"
                >
                  <span class="material-icons-outlined md-small mr-1">code</span>
                  Metadata
                </div>
                <div v-if="tagNames.length > 0" class="dropdown-list--item" @click="addRule('tag')">
                  <span class="material-icons-outlined md-small mr-1">sell</span>
                  Tag
                </div>
                <div
                  v-if="tagNames.length > 0"
                  class="dropdown-list--item"
                  @click="addRule('untagged')"
                >
                  <span class="material-icons-outlined md-small mr-1">label_off</span>
                  Not Tagged
                </div>
              </div>
            </vntg-searchable-list>
          </div>
        </details>
        <button
          type="button"
          :class="`btn btn-tertiary btn-xs ${
            filterCount <= 1 && selectedProvider === null ? 'btn-disabled' : ''
          }`"
          aria-expanded="true"
          @click="removeFilter(this)"
        >
          Delete Filter
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceInventoryFilterEnumRule from "./ResourceInventoryFilterEnumRule.vue";
import ResourceReportFilterGroupedKeyEnumRule from "./ResourceReportFilterGroupedKeyEnumRule.vue";
import ResourceInventoryFilterKeyEnumRule from "./ResourceInventoryFilterKeyEnumRule.vue";
import ResourceInventoryFilterKeyValueRule from "./ResourceInventoryFilterKeyValueRule.vue";
import ResourceInventoryFilterTextRule from "./ResourceInventoryFilterTextRule.vue";
import ResourceInventoryFilterKeyNotPresentRule from "./ResourceInventoryFilterKeyNotPresentRule.vue";
import Rails from "@rails/ujs";

export default {
  name: "ResourceInventoryFilter",
  props: [
    "accounts",
    "providerAccounts",
    "filterCount",
    "index",
    "metadataKeys",
    "providers",
    "regions",
    "removeFilter",
    "fetchMetadataValues",
    "fetchTagValues",
    "resourceTypes",
    "serializedFilter",
    "tagNames",
    "aliases",
    "uuid",
    "loading",
  ],

  components: {
    ResourceInventoryFilterEnumRule,
    ResourceReportFilterGroupedKeyEnumRule,
    ResourceInventoryFilterKeyEnumRule,
    ResourceInventoryFilterKeyValueRule,
    ResourceInventoryFilterTextRule,
    ResourceInventoryFilterKeyNotPresentRule,
  },

  data: () => ({
    selectedProvider: null,
    rules: [],
  }),

  watch: {
    selectedProvider() {
      this.$emit("filterChanged");
    },
    rules: {
      deep: true,
      handler() {
        this.$emit("filterChanged");
      },
    },
  },

  computed: {
    header() {
      return this.index === 0 ? "All Resources" : "Or";
    },
    accountLabel() {
      return this.aliasesForProvider(this.selectedProvider)?.account || "Account";
    },
    providerAccountLabel() {
      return this.aliasesForProvider(this.selectedProvider)?.provider_account || "Provider Account";
    },
    uuidLabel() {
      return this.aliasesForProvider(this.selectedProvider)?.uuid || "UUID";
    },
    availableAccounts() {
      return this.accountsForProvider(this.selectedProvider);
    },
    availableProviderAccounts() {
      return this.providerAccountsForProvider(this.selectedProvider);
    },
    availableRegions() {
      return this.regionsForProvider(this.selectedProvider);
    },
    availableResourceTypes() {
      return this.resourceTypesForProvider(this.selectedProvider);
    },
    availableMetadataKeys() {
      return this.metadataKeysForProvider(this.selectedProvider);
    },
    accountRulePresent() {
      return this.rules.some((rule) => rule.name === this.accountLabel);
    },
    providerAccountRulePresent() {
      return this.rules.some((rule) => rule.name === this.providerAccountLabel);
    },
    regionRulePresent() {
      return this.rules.some((rule) => rule.name === "Region");
    },
    resourceTypeRulePresent() {
      return this.rules.some((rule) => rule.name === "Resource Type");
    },
    metadataTypeRulePresent() {
      return this.rules.some((rule) => rule.name === "Metadata");
    },
    sortedProviders() {
      return this.providers.sort((a, b) => a.name.localeCompare(b.name));
    },
  },

  mounted: function () {
    if (this.providers.length === 1) {
      this.selectProvider(this.providers[0]);
    }

    if (this.serializedFilter) {
      const serializedRules = this.serializedFilter["^"] || [];
      const providerRule = serializedRules.find(
        (rule) => rule["="] && rule["="][0] === "provider_id"
      );

      // A rule needs at least a provider before it filters any records.
      if (providerRule) {
        const provider_id = providerRule["="][1];
        const provider = this.providers.find((provider) => provider.id === provider_id);

        this.selectProvider(provider);

        serializedRules.forEach((serializedRule) => {
          if (serializedRule["="] && serializedRule["="][0] === "provider_id") {
            // Skip the provider rule, we already handled it.
            return;
          }

          const operator = Object.keys(serializedRule)[0];

          // If we have a list of rules, we need to dig deeper to know which type it is.
          if (operator === "^") {
            const firstQuery = serializedRule["^"][0];

            if (firstQuery === null) {
              // no-op
            } else {
              const queryField = Object.values(firstQuery)[0][0];

              if (/^tag/i.test(queryField)) {
                const isUntagged = serializedRule["^"].find((rule) => {
                  const operator = Object.keys(rule)[0];

                  if (operator === "!&=") {
                    return true;
                  }
                });

                if (isUntagged) {
                  this.addRule("untagged", serializedRule, false);
                } else {
                  this.addRule("tag", serializedRule, false);
                }
              } else if (/^type/i.test(queryField)) {
                const hasMetadataQuery = serializedRule["^"].find((rule) => {
                  const operands = Object.values(rule)[0];
                  const subQueryField = operands[0];

                  if (/^metadata/i.test(subQueryField)) {
                    return true;
                  }
                });

                if (hasMetadataQuery) {
                  this.addRule("metadata", serializedRule, false);
                } else {
                  this.addRule("type", serializedRule, false);
                }
              } else {
                this.addRule(queryField, serializedRule, false);
              }
            }
          } else if (operator === "||") {
            // Rules that support multiple values which are OR'd together.
            const orValues = serializedRule[operator];

            if (orValues.length === 0) {
              return;
            } else {
              const ruleOperator = Object.keys(orValues[0])[0];
              const ruleType = orValues[0][ruleOperator][0];
              this.addRule(ruleType, serializedRule, false);
            }
          } else if (operator === "!&") {
            this.addRule("untagged", serializedRule, false);
          } else {
            const ruleType = serializedRule[operator][0];
            this.addRule(ruleType, serializedRule, false);
          }
        });
      }
    }
  },

  methods: {
    aliasesForProvider(provider) {
      return (
        this.aliases.find((alias) => {
          return alias.provider_id === provider.id;
        }) || {}
      );
    },
    resourceTypesForProvider(provider) {
      return this.resourceTypes.filter((type) => {
        return type.provider_id === provider.id;
      });
    },
    accountsForProvider(provider) {
      return this.accounts.filter((account) => {
        return account.provider_id === provider.id;
      });
    },
    providerAccountsForProvider(provider) {
      return this.providerAccounts.filter((account) => {
        return account.provider_id === provider.id;
      });
    },
    regionsForProvider(provider) {
      return this.regions.filter((region) => {
        return region.provider_id === provider.id;
      });
    },
    metadataKeysForProvider(provider) {
      return this.metadataKeys.filter((key) => {
        return key.provider_id === provider.id;
      });
    },
    addRule(ruleType, serializedRule, closeDropdown = true) {
      switch (true) {
        case /^region/i.test(ruleType):
          this.rules.push({
            type: "ResourceInventoryFilterEnumRule",
            name: "Region",
            field: "region_id",
            uuid: crypto.randomUUID(),
            icon: "outlined_flag",
            serializedRule: serializedRule,
            options: this.availableRegions,
          });
          break;
        case /^account/i.test(ruleType):
          this.rules.push({
            type: "ResourceInventoryFilterEnumRule",
            name: this.accountLabel,
            field: "account_identifier",
            uuid: crypto.randomUUID(),
            icon: "account_circle",
            serializedRule: serializedRule,
            options: this.availableAccounts,
          });
          break;
        case /^provider_account/i.test(ruleType):
          this.rules.push({
            type: "ResourceInventoryFilterEnumRule",
            name: this.providerAccountLabel,
            field: "provider_account_identifier",
            uuid: crypto.randomUUID(),
            icon: "supervised_user_circle",
            serializedRule: serializedRule,
            options: this.availableProviderAccounts,
          });
          break;
        case /^label/i.test(ruleType):
          this.rules.push({
            type: "ResourceInventoryFilterTextRule",
            name: "Label",
            field: "label",
            uuid: crypto.randomUUID(),
            icon: "label",
            serializedRule: serializedRule,
          });
          break;
        case /^uuid/i.test(ruleType):
          this.rules.push({
            type: "ResourceInventoryFilterTextRule",
            name: this.uuidLabel,
            field: "uuid",
            uuid: crypto.randomUUID(),
            icon: "fingerprint",
            serializedRule: serializedRule,
          });
          break;
        case /^type/i.test(ruleType):
          this.rules.push({
            type: "ResourceInventoryFilterEnumRule",
            name: "Resource Type",
            field: "type",
            uuid: crypto.randomUUID(),
            icon: "category",
            serializedRule: serializedRule,
            options: this.availableResourceTypes,
          });
          break;
        case /^metadata/i.test(ruleType):
          this.rules.push({
            type: "ResourceReportFilterGroupedKeyEnumRule",
            name: "Metadata",
            field: "metadata",
            groupLabel: "Resource Type",
            uuid: crypto.randomUUID(),
            icon: "code",
            fetchValues: (group, key) =>
              this.fetchMetadataValues(this.selectedProvider, group, key),
            serializedRule: serializedRule,
            options: this.availableMetadataKeys,
          });
          break;
        case /^tag/i.test(ruleType):
          this.rules.push({
            type: "ResourceInventoryFilterKeyEnumRule",
            name: "Tag",
            field: "tags",
            keyField: "name",
            valueField: "value",
            uuid: crypto.randomUUID(),
            icon: "sell",
            fetchValues: this.fetchTagValues,
            serializedRule: serializedRule,
            options: this.tagNames,
          });
          break;
        case /^untagged/i.test(ruleType):
          this.rules.push({
            type: "ResourceInventoryFilterKeyNotPresentRule",
            name: "Not Tagged",
            field: "tags",
            keyField: "name",
            uuid: crypto.randomUUID(),
            icon: "label_off",
            serializedRule: serializedRule,
            options: this.tagNames,
          });
          break;
        default:
          console.error("Unknown Rule type", ruleType);
      }

      if (closeDropdown) {
        this.$refs.ruleSelector.open = false;
      }
    },

    removeRule(rule) {
      const index = this.rules.findIndex((r) => r.uuid === rule.uuid);
      this.rules.splice(index, 1);
    },

    onRuleChanged() {
      this.$emit("filterChanged");
    },

    selectProvider(provider, clearRules = true) {
      if (provider?.id !== this.selectedProvider?.id && clearRules) {
        this.rules = [];
      }

      this.selectedProvider = provider;
      this.$refs.providerSelector.open = false;
    },

    serialize() {
      if (this.selectedProvider == null) {
        return null;
      } else {
        const rules = this.$refs.rules || [];
        const result = {
          "^": [{ "=": ["provider_id", this.selectedProvider.id] }].concat(
            rules.map((rule) => rule.serialize()).filter((rule) => rule !== null)
          ),
        };

        return result;
      }
    },
  },
};
</script>

<style scoped></style>
