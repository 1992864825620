<template>
  <div>{{ emoji }} Vue Enabled</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "VueEnabled",
  props: {
    emoji: String,
  },
});
</script>
