<template>
  <div class="filter-rule--row align-items-start">
    <span class="rule-row-label mb-2 mt-1">{{
      index === 0 ? "where" : "and"
    }}</span>
    <div class="btn btn-tertiary btn-sm mb-2">
      <span class="material-icons-outlined md-small mr-1">{{ icon }}</span>
      {{ name }}
    </div>
    <details class="filter-dropdown ml-2 mb-2" ref="operatorSelector">
      <summary class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedOperator.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list drop-right">
        <vntg-searchable-list>
          <li
            v-for="operator in operators"
            :class="`dropdown-list--item ${
              operator === selectedOperator
                ? 'dropdown-list--item--disabled'
                : ''
            }`"
            @click="selectOperator(operator)"
          >
            {{ operator.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <div class="d-flex flex-wrap">
      <div
        v-for="(option, index) in selectedOptions"
        class="pill-section mb-2 flex-shrink-0"
      >
        <span class="btn btn-outline btn-pill-deletable btn-sm mb-0 ml-2">
          <span class="pill--truncate" :title="`${option.name}`">
            {{ option.name }}
          </span>
          <button class="btn-link d-flex ml-2">
            <span
              class="material-icons-outlined icon"
              @click="removeOption(index)"
            >
              close
            </span>
          </button>
        </span>
      </div>
    </div>
    <details class="filter-dropdown mx-2 mb-2" ref="optionSelector">
      <summary
        v-if="selectedOptions.length === 0"
        class="btn btn-outline btn-sm btn-icon"
      >
        <span class="material-icons-outlined icon" aria-hidden="true">
          ads_click
        </span>
        {{ selectPromptText(this.name) }}
      </summary>
      <summary
        v-else
        :class="`btn btn-sm btn-invisible btn-icon ${
          remainingOptions.length === 0 ? 'btn-disabled' : ''
        }`"
      >
        <span class="material-icons-outlined md-small" aria-hidden="true"
          >add</span
        >
      </summary>
      <div v-if="remainingOptions.length > 0" class="filter-dropdown--list">
        <vntg-searchable-list>
          <li
            v-for="option in remainingOptions"
            :class="`dropdown-list--item ${
              selectedOptions.includes(option)
                ? 'dropdown-list--item--disabled'
                : ''
            }`"
            @click="selectOption(option)"
          >
            {{ option.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <button
      type="button"
      class="btn btn-sm btn-invisible btn-icon mb-2"
      aria-expanded="true"
      @click="removeRule(this)"
    >
      <span class="material-icons-outlined md-small">delete</span>
    </button>
  </div>
</template>

<script>
import SelectPromptMixin from "../mixins/SelectPromptMixin";

const operators = [
  { name: "is", value: "=" },
  { name: "is not", value: "!=" },
];

export default {
  name: "ResourceInventoryFilterEnumRule",
  mixins: [SelectPromptMixin],
  props: [
    "field",
    "icon",
    "index",
    "name",
    "options",
    "removeRule",
    "serializedRule",
    "uuid",
  ],
  data: (_component) => ({
    operators: operators,
    selectedOptions: [],
    selectedOperator: operators[0],
  }),

  watch: {
    selectedOptions: {
      deep: true,
      handler() {
        this.$emit("ruleChanged");
      },
    },
    selectedOperator() {
      this.$emit("ruleChanged");
    },
  },
  computed: {
    sortedOptions() {
      return this.options.sort((a, b) => a.name.localeCompare(b.name));
    },
    remainingOptions() {
      return this.sortedOptions.filter(
        (option) => !this.selectedOptions.includes(option)
      );
    },
  },
  mounted: function () {
    if (this.serializedRule) {
      // Changes depending on whether our operator equals IS or IS NOT.
      const serializedOptions =
        this.serializedRule["||"] || this.serializedRule["^"];

      if (serializedOptions.length === 0) {
        return;
      }

      // We expect all options to contain the same operator value.
      const operatorValue = Object.keys(serializedOptions[0])[0];
      const operator = this.operators.find(
        (operator) => operator.value === operatorValue
      );

      this.selectedOperator = operator;

      serializedOptions.forEach((serializedOption) => {
        const option = this.options.find(
          (option) => option.value === serializedOption[operatorValue][1]
        );

        if (option) {
          this.selectedOptions.push(option);
        }
      });
    }
  },
  methods: {
    selectOperator(operator, closeDropdown = true) {
      this.selectedOperator = operator;

      if (closeDropdown) {
        this.$refs.operatorSelector.open = false;
      }
    },
    selectOption(option, closeDropdown = true) {
      this.selectedOptions.push(option);

      if (closeDropdown) {
        this.$refs.optionSelector.open = false;
      }
    },
    removeOption(index) {
      this.selectedOptions.splice(index, 1);
    },
    serialize() {
      if (this.selectedOptions.length === 0) {
        return null;
      } else {
        const query = {};
        const connector = this.selectedOperator.value === "=" ? "||" : "^";

        query[connector] = this.selectedOptions.map((option) => {
          const optionQuery = {};
          optionQuery[this.selectedOperator.value] = [this.field, option.value];

          return optionQuery;
        });

        return query;
      }
    },
  },
};
</script>

<style scoped></style>
