<template>
  <div>
    <vntg-multi-select-input
      :id-prop="idProp"
      :options-prop="optionsProp"
      :selected-prop="jsonSelection"
      :input-name="inputName"
      multiple
      @vntg:multi-select-on-select="handleSelection"
      hide-selections
      placeholder="Add teams..."
      ref="childComponent"
    />
    <div v-if="selected.length > 0">
      <div class="d-flex align-items-center mt-3 mb-2">
        <p class="text-bolder m-0">Modified Team Access</p>
        <div class="temp-tooltip">
          <div class="temp-tooltip--msg">
            Modify the inherited access a team has been granted by this
            Workspace or folder. Removing a team's access here will override any
            inherited access granted by this Workspace or folder.
          </div>
          <span class="material-icons-outlined md-small text-muted">info</span>
        </div>
      </div>

      <ul class="teams-list">
        <li v-for="item of selected">
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <div v-if="item.name === 'Everyone'" class="Icon Icon-global">
                <span class="material-icons icon">public</span>
              </div>
              <div v-else class="Icon Icon-secondary">
                <span class="material-icons icon">group</span>
              </div>
              <div>
                <p class="mb-0">
                  {{ item.name }}
                </p>
                <p v-if="item.members == 1" class="mb-0 small text-muted">
                  1 Member
                </p>
                <p v-else class="mb-0 small text-muted">
                  {{ item.members }} Members
                </p>
              </div>
            </div>
            <AppDropdown>
              <template #toggler>
                <button class="btn btn-sm btn-outline dropdown-toggle">
                  <span v-if="item.access == 'allowed'">Can Access</span>
                  <span v-else>Cannot Access</span>
                </button>
              </template>
              <AppDropdownContent>
                <AppDropdownItem>
                  <div class="drop-menu--item" @click="handleAllowal(item)">
                    <div class="d-flex align-items-center w-100">
                      <span
                        v-if="item.access == 'allowed'"
                        class="material-icons color-text-link mr-3"
                        >check</span
                      >
                      <span
                        v-else
                        class="material-icons color-text-link mr-3"
                        style="color: transparent"
                        >check</span
                      >
                      <div>
                        <p class="mb-0 text-bold">Can Access</p>
                        <p
                          class="mb-0 small text-muted text-wrap"
                          style="line-height: 17px"
                        >
                          This team will have access to this item.
                        </p>
                      </div>
                    </div>
                  </div>
                </AppDropdownItem>
                <AppDropdownItem>
                  <div class="drop-menu--divider"></div>
                </AppDropdownItem>
                <AppDropdownItem>
                  <div class="drop-menu--item" @click="handleDenial(item)">
                    <div class="d-flex align-items-center w-100">
                      <span
                        v-if="item.access == 'denied'"
                        class="material-icons color-text-link mr-3"
                        >check</span
                      >
                      <span
                        v-else
                        class="material-icons color-text-link mr-3"
                        style="color: transparent"
                        >check</span
                      >
                      <div>
                        <p class="mb-0 text-bold">Cannot Access</p>
                        <p
                          class="mb-0 small text-muted text-wrap"
                          style="line-height: 17px"
                        >
                          This team will not have access to this item.
                        </p>
                      </div>
                    </div>
                  </div>
                </AppDropdownItem>
                <AppDropdownItem>
                  <div class="drop-menu--divider"></div>
                </AppDropdownItem>
                <AppDropdownItem>
                  <div class="drop-menu--item" @click="handleRemoval(item)">
                    <div class="d-flex align-items-center w-100">
                      <span
                        class="material-icons mr-3"
                        style="color: transparent"
                        >check</span
                      >
                      <div>
                        <p class="mb-0 text-bold">Reset Access</p>
                        <p
                          class="mb-0 small text-muted text-wrap"
                          style="line-height: 17px"
                        >
                          This team will not have access to this item unless
                          they are assigned to this Workspace.
                        </p>
                      </div>
                    </div>
                  </div>
                </AppDropdownItem>
                <AppDropdownItem>
                  <div class="drop-menu--divider"></div>
                </AppDropdownItem>
                <AppDropdownItem>
                  <a
                    href="#"
                    target="_blank"
                    class="drop-menu--item d-flex align-items-center text-muted small"
                  >
                    <span class="material-icons-outlined md-small mr-1"
                      >info</span
                    >
                    Learn more about access management.
                  </a>
                </AppDropdownItem>
              </AppDropdownContent>
            </AppDropdown>
          </div>
        </li>
      </ul>
    </div>
    <div
      class="d-flex flex-column py-2 px-3 border rounded-lg mt-3 align-items-start"
      v-else
    >
      <div class="d-flex align-items-center mb-1">
        <span class="material-icons md-small mr-2">group</span>
        <p class="mb-0 text-bold">Owners and Workspace Teams Only</p>
      </div>
      <p class="mb-0 small text-muted">
        Currently, only Organization Owners and teams that have been granted
        access to this workspace will be able to view this item.
      </p>
    </div>
  </div>
</template>

<script>
import AppDropdown from "./AppDropdown.vue";
import AppDropdownContent from "./AppDropdownContent.vue";
import AppDropdownItem from "./AppDropdownItem.vue";
import { defineComponent } from "vue";
import StylesheetMixin from "./mixins/StylesheetMixin";
import MultiSelectInput from "./MultiSelectInput.ce.vue";
export default defineComponent({
  name: "TeamSelector",
  components: {
    "child-component": MultiSelectInput,
    AppDropdown,
    AppDropdownContent,
    AppDropdownItem,
  },
  props: {
    idProp: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    optionsProp: {
      type: String,
      default: "[]",
    },
    selectedProp: {
      type: String,
      default: "[]",
    },
    parentNameProp: {
      type: String,
      default: "[]",
    },
  },
  mixins: [StylesheetMixin],
  data: () => ({
    selected: [], // this is for display
    teamsWithGrant: [],

    teamsToAllow: [], // array of teams who will receive allow grants
    teamsToDeny: [], // array of teams who will receive deny grants
    teamsToReset: [], // array of teams whose grants will be deleted
    jsonSelection: JSON.stringify([]),
  }),
  mounted() {
    this.selected = JSON.parse(this.selectedProp);
    // teamsWithGrant is what is currently persisted, and compared against
    // to determine which grants to flip to deny.
    this.teamsWithGrant = JSON.parse(this.selectedProp);

    this.jsonSelection = this.selectedProp;
  },
  methods: {
    // handle selection should either add to the list of teams to be added, or
    // remove from the list of teams to be removed.
    handleSelection(event) {
      const selectedTeam = event.detail[0].event;
      this.selected.push(selectedTeam);
      this.jsonSelection = JSON.stringify(this.selected);

      this.selected.forEach((x, i) => {
        if (x.value == selectedTeam.value) {
          this.selected[i].access = "allowed";
        }
      });

      // if in teamsToReset, remove it
      this.teamsToReset = this.teamsToReset.filter(
        (x) => x != selectedTeam.value
      );

      this.teamsToAllow.push(selectedTeam.value);

      this.updateHiddenInputs();
    },

    handleAllowal(item) {
      // flip in memory
      this.selected.forEach((x, i) => {
        if (x.value == item.value) {
          this.selected[i].access = "allowed";
        }
      });

      const selectedTeam = item;

      // remove it from teamsToDeny if it is there
      this.teamsToDeny = this.teamsToDeny.filter((x) => x != item.value);

      // if not in current set of teams with grants, add to it
      if (this.teamsToAllow.find((t) => t == selectedTeam.value)) {
        this.teamsToAllow = this.teamsToAllow.filter(
          (x) => x != selectedTeam.value
        );
      } else {
        const existingTeam = this.teamsWithGrant.find(
          (t) => t.value == selectedTeam.value
        );
        if (!existingTeam) {
          this.teamsToAllow.push(selectedTeam.value);
        } else {
          if (existingTeam.access != "allowed") {
            this.teamsToAllow.push(selectedTeam.value);
          }
        }
      }

      this.updateHiddenInputs();
    },
    // handle removal should remove the team from the list of teams to be added, or
    // add to the list of teams to be removed.
    handleDenial(item) {
      // flip in memory
      this.selected.forEach((x, i) => {
        if (x.value == item.value) {
          this.selected[i].access = "denied";
        }
      });

      const selectedTeam = item;
      // remove it from teamsToAllow if it is there
      this.teamsToAllow = this.teamsToAllow.filter(
        (x) => x != selectedTeam.value
      );

      if (this.teamsToDeny.find((t) => t == selectedTeam.value)) {
        this.teamsToDeny = this.teamsToDeny.filter(
          (x) => x != selectedTeam.value
        );
      } else {
        const existingTeam = this.teamsWithGrant.find(
          (t) => t.value == selectedTeam.value
        );
        if (!existingTeam) {
          this.teamsToDeny.push(selectedTeam.value);
        } else {
          if (existingTeam.access != "denied") {
            this.teamsToDeny.push(selectedTeam.value);
          }
        }
      }

      this.updateHiddenInputs();
    },

    handleRemoval(item) {
      this.selected = this.selected.filter((x) => {
        return x.value != item.value;
      });
      this.jsonSelection = JSON.stringify(this.selected);

      const selectedTeam = item;

      // if in teamsWithGrant, add to teams_to_remove
      if (this.teamsWithGrant.find((t) => t.value == selectedTeam.value)) {
        this.teamsToReset.push(selectedTeam.value);
      }

      // if in teamsToAllow, remove
      this.teamsToAllow = this.teamsToAllow.filter(
        (x) => x != selectedTeam.value
      );

      // if in teamsToDeny, remove
      this.teamsToDeny = this.teamsToDeny.filter(
        (x) => x != selectedTeam.value
      );

      this.updateHiddenInputs();
    },

    updateHiddenInputs() {
      let container = document.getElementById("vntg-grant-fields");
      container.innerHTML = "";

      this.teamsToAllow.forEach((selected) => {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = "teams_to_allow_tokens[]";
        input.value = selected;
        container.appendChild(input);
      });

      this.teamsToDeny.forEach((selected) => {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = "teams_to_deny_tokens[]";
        input.value = selected;
        container.appendChild(input);
      });

      this.teamsToReset.forEach((selected) => {
        let input = document.createElement("input");
        input.type = "hidden";
        input.name = "teams_to_reset_tokens[]";
        input.value = selected;
        container.appendChild(input);
      });
    },
  },
});
</script>

<style lang="scss">
.drop {
  position: relative;

  &:hover {
    .dropdown-toggle {
      background-color: #f7f7f9 !important;
    }
  }
}

.dropdown-toggle {
  font-size: 12px !important;
  line-height: 1 !important;
  padding: 8px !important;
  font-weight: normal !important;
  &:hover {
    background-color: #f7f7f9 !important;
  }
}

.drop-menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  width: 330px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid #e3e3ed;
  top: 100%;
  z-index: 3;
  padding: 4px 0;
}

.drop-menu--item {
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  &:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f7f7f9;
  }
}

.drop-menu--divider {
  height: 1px;
  width: 100%;
  margin: 4px 0;
  background-color: #e3e3ed;
}

.teams-list {
  margin: 0;
  border: 1px solid #e3e3e9;
  border-radius: 6px;

  li {
    padding: 12px;
    border-bottom: 1px solid #e3e3e9;

    &:last-child {
      border-bottom: none;
    }
  }
}

.temp-tooltip {
  position: relative;
  display: flex;
  margin-left: 4px;
  cursor: default;

  &:hover {
    .temp-tooltip--msg {
      display: block;
    }
  }

  .temp-tooltip--msg {
    position: absolute;
    bottom: 20px;
    width: 250px;
    left: 50%;
    margin-left: -125px;
    background: rgba(0, 0, 0, 0.9);
    color: white;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    padding: 12px;
    border-radius: 6px;
    display: none;
  }
}
</style>
