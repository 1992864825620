<template>
  <div class="filter-rule--row align-items-start">
    <span class="rule-row-label mb-2 mt-1">{{
      index === 0 ? "where" : "and"
    }}</span>
    <div class="btn btn-tertiary btn-sm mb-2">
      <span class="material-icons-outlined md-small mr-1">{{ icon }}</span>
      {{ name }}
    </div>
    <span class="ml-2 mt-1"> with </span>
    <div class="d-flex flex-wrap">
      <div
        v-for="(option, index) in selectedOptions"
        class="pill-section mb-2 flex-shrink-0"
      >
        <span class="btn btn-outline btn-pill-deletable btn-sm mb-0 ml-2">
          <span class="pill--truncate" :title="`${option.name}`">
            {{ option.name }}
          </span>
          <button class="btn-link d-flex ml-2">
            <span
              class="material-icons-outlined icon"
              @click="removeOption(index)"
            >
              close
            </span>
          </button>
        </span>
      </div>
    </div>
    <details class="filter-dropdown mx-2 mb-2" ref="optionSelector">
      <summary
        v-if="selectedOptions.length === 0"
        class="btn btn-outline btn-sm btn-icon"
      >
        Any Key
      </summary>
      <summary
        v-else
        :class="`btn btn-sm btn-invisible btn-icon ${
          remainingOptions.length === 0 ? 'btn-disabled' : ''
        }`"
      >
        <span class="material-icons-outlined md-small" aria-hidden="true"
          >add</span
        >
      </summary>
      <div v-if="remainingOptions.length > 0" class="filter-dropdown--list">
        <vntg-searchable-list>
          <li
            v-for="option in remainingOptions"
            :class="`dropdown-list--item ${
              selectedOptions.includes(option)
                ? 'dropdown-list--item--disabled'
                : ''
            }`"
            @click="selectOption(option)"
          >
            {{ option.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <button
      type="button"
      class="btn btn-sm btn-invisible btn-icon mb-2"
      aria-expanded="true"
      @click="removeRule(this)"
    >
      <span class="material-icons-outlined md-small">delete</span>
    </button>
  </div>
</template>

<script>
import SelectPromptMixin from "../mixins/SelectPromptMixin";

export default {
  name: "ResourceInventoryFilterKeyNotPresentRule",
  mixins: [SelectPromptMixin],
  props: [
    "field",
    "keyField",
    "icon",
    "index",
    "name",
    "options",
    "removeRule",
    "serializedRule",
    "uuid",
  ],
  data: (_component) => ({
    selectedOptions: [],
  }),

  watch: {
    selectedOptions: {
      deep: true,
      handler() {
        this.$emit("ruleChanged");
      },
    },
  },
  computed: {
    sortedOptions() {
      return this.options.sort((a, b) => a.name.localeCompare(b.name));
    },
    remainingOptions() {
      return this.sortedOptions.filter(
        (option) => !this.selectedOptions.includes(option)
      );
    },
  },
  mounted: function () {
    if (this.serializedRule) {
      if (this.serializedRule["!&"] !== undefined) {
        // FilterKeyNotPresentRule is unique in that it modifies the filter set without the user needing
        // to select an additional input. We emit ruleChanged here to update any relevant form inputs
        // as soon as the rule is loaded.
        this.$emit("ruleChanged");
        return;
      } else {
        this.serializedRule["^"].forEach((serializedOption) => {
          const option = this.options.find(
            (option) => option.name === serializedOption["!&="][1]
          );

          if (option) {
            this.selectedOptions.push(option);
          }
        });
      }
    }
  },
  methods: {
    selectOption(option, closeDropdown = true) {
      this.selectedOptions.push(option);

      if (closeDropdown) {
        this.$refs.optionSelector.open = false;
      }
    },
    removeOption(index) {
      this.selectedOptions.splice(index, 1);
    },
    serialize() {
      const query = {};

      if (this.selectedOptions.length === 0) {
        query["!&"] = [this.field + "." + this.keyField];
      } else {
        query["^"] = this.selectedOptions.map((option) => {
          const optionQuery = {};
          optionQuery["!&="] = [this.field + "." + this.keyField, option.name];

          return optionQuery;
        });
      }

      return query;
    },
  },
};
</script>

<style scoped></style>
