<template>
  <div class="filter-rule--row align-items-start">
    <span class="rule-row-label mt-1 mb-2">{{
      index == 0 ? "where" : "and"
    }}</span>
    <div class="btn btn-tertiary btn-sm mb-2">
      <span class="material-icons-outlined md-small mr-1">{{ icon }}</span>
      {{ name }}
    </div>
    <details
      v-if="hasOptionGroups"
      class="filter-dropdown ml-2 mb-2"
      ref="optionGroupSelector"
    >
      <summary
        v-if="selectedOptionGroup === null"
        class="btn btn-outline btn-sm btn-icon"
      >
        <span class="material-icons-outlined icon" aria-hidden="true">
          ads_click
        </span>
        Select a {{ groupName || "Group" }}
      </summary>
      <summary v-else class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedOptionGroup.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list">
        <vntg-searchable-list>
          <li
            v-for="optionGroup in availableOptionGroups"
            class="dropdown-list--item text-wrap"
            @click="selectOptionGroup(optionGroup)"
          >
            {{ optionGroup.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <details class="filter-dropdown ml-2 mb-2" ref="optionSelector">
      <summary
        v-if="selectedOption === null"
        :class="`btn btn-outline btn-sm btn-icon ${
          hasOptionGroups && selectedOptionGroup === null ? 'disabled' : ''
        }`"
      >
        <span class="material-icons-outlined icon" aria-hidden="true">
          ads_click
        </span>
        Select a Key
      </summary>
      <summary v-else class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedOption.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list drop-right">
        <vntg-searchable-list>
          <li
            v-for="option in availableOptions"
            class="dropdown-list--item text-wrap"
            @click="selectOption(option)"
          >
            {{ option.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <details class="filter-dropdown ml-2 mb-2" ref="operatorSelector">
      <summary class="btn btn-outline btn-sm">
        <span class="pill--truncate">
          {{ selectedOperator.name }}
        </span>
      </summary>
      <div class="filter-dropdown--list">
        <vntg-searchable-list>
          <li
            v-for="operator in operators"
            class="dropdown-list--item"
            @click="selectOperator(operator)"
          >
            {{ operator.name }}
          </li>
        </vntg-searchable-list>
      </div>
    </details>
    <input
      class="form-control mx-2 mb-2 w-auto"
      type="text"
      name="ruleValue"
      @change="serialize"
      v-model="selectedValue"
    />
    <button
      type="button"
      class="btn btn-sm btn-invisible btn-icon mb-2"
      aria-expanded="true"
      @click="removeRule(this)"
    >
      <span class="material-icons-outlined md-small">delete</span>
    </button>
  </div>
</template>

<script>
const operators = [
  { name: "is", value: "=" },
  { name: "is not", value: "!=" },
  { name: "contains", value: "=~" },
  { name: "does not contain", value: "!=~" },
];

export default {
  name: "ResourceInventoryFilterKeyValueRule",
  props: [
    "groupName",
    "icon",
    "index",
    "name",
    "options",
    "removeRule",
    "serializedRule",
    "uuid",
  ],
  data: (_component) => ({
    operators: operators,
    selectedOperator: operators[0],
    selectedOptionGroup: null,
    selectedOption: null,
    selectedValue: null,
    disableOptionGroups: false,
  }),
  watch: {
    selectedOperator() {
      this.$emit("ruleChanged");
    },
    selectedOption() {
      this.$emit("ruleChanged");
    },
    selectedValue() {
      this.$emit("ruleChanged");
    },
  },
  computed: {
    availableOptionGroups() {
      const optionGroups = this.options
        .filter((option) => option.group)
        .map((option) => option.group);

      return [
        ...new Map(optionGroups.map((group) => [group.value, group])).values(),
      ].sort((a, b) => a.name.localeCompare(b.name));
    },
    hasOptionGroups() {
      return !this.disableOptionGroups && this.availableOptionGroups.length > 0;
    },
    selectedKey() {
      return this.selectedOption && this.selectedOption.value;
    },
    availableOptions() {
      if (this.hasOptionGroups) {
        if (this.selectedOptionGroup === null) {
          return [];
        } else {
          const groupedOptions = this.options.filter(
            (option) => option.group.value === this.selectedOptionGroup.value
          );
          return groupedOptions.sort((a, b) => a.name.localeCompare(b.name));
        }
      } else {
        return this.options.sort((a, b) => a.name.localeCompare(b.name));
      }
    },
  },
  mounted: function () {
    if (this.serializedRule) {
      let serializedRule = this.serializedRule;
      if (serializedRule["^"] !== null) {
        // Means this serialized rule has a group.
        const queries = serializedRule["^"];

        // First query will be the group, second query will be the key/value pair.
        // e.g. { "^": [ { "=": [ "groupField", "groupValue" ] }, { "=": [ "key", "value" ] } ] }
        const groupValue = queries[0]["="][1];
        const selectedOptionGroup = this.availableOptionGroups.find(
          (optionGroup) => optionGroup.value === groupValue
        );
        this.selectedOptionGroup = selectedOptionGroup;

        // Update this.serializedRule to be the key/value pair for the rest of the deserialization process.
        serializedRule = queries[1];
      } else {
        // Ensure we're not showing the option groups dropdown for compatibility purposes.
        this.data.disableOptionGroups = true;
      }

      // Now process the key/value pair.
      const operatorValue = Object.keys(serializedRule)[0];
      const operator = this.operators.find(
        (operator) => operator.value === operatorValue
      );
      const option = this.options.find(
        (option) => option.value === serializedRule[operator.value][0]
      );

      this.selectedOperator = operator;
      this.selectedOption = option;
      this.selectedValue = serializedRule[operator.value][1];
    }
  },
  methods: {
    selectOperator(operator, closeDropdown = true) {
      this.selectedOperator = operator;

      if (closeDropdown) {
        this.$refs.operatorSelector.open = false;
      }
    },
    selectOptionGroup(optionGroup, closeDropdown = true) {
      if (optionGroup?.value !== this.selectedOptionGroup?.value) {
        this.selectedOptionGroup = optionGroup;
        this.selectedOption = null;
      }

      if (closeDropdown) {
        this.$refs.optionGroupSelector.open = false;
      }
    },
    selectOption(option, closeDropdown = true) {
      this.selectedOption = option;

      if (closeDropdown) {
        this.$refs.optionSelector.open = false;
      }
    },
    serialize() {
      if (this.selectedKey === null) {
        return null;
      } else {
        const keyValueQuery = {};
        keyValueQuery[this.selectedOperator.value] = [
          this.selectedKey,
          this.selectedValue,
        ];

        if (this.selectedOptionGroup !== null) {
          const query = {};
          const groupQuery = {};

          // Need to query for both the group (e.g. the ResourceType that has the metadata field), and the key/value pair.
          groupQuery["="] = [
            this.selectedOptionGroup.field,
            this.selectedOptionGroup.value,
          ];
          query["^"] = [groupQuery, keyValueQuery];

          return query;
        } else {
          return keyValueQuery;
        }
      }
    },
  },
};
</script>

<style scoped></style>
